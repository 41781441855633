import React, { useEffect, useState } from 'react';
import { Typography, Spin, Empty } from 'antd';

import { getAvailableBillerSuppliers } from 'src/apis/distributorSupplier';

import { colors } from 'src/styles';
import DistributorSuppliertItem from './DistributorSuppliertItem';

const { Text } = Typography;

const DistributorSupplierList = () => {
  const [distributorSuppliers, setDistributorSuppliers] = useState([]);
  const [loading, setLoading] = useState([]);

  const handleAllAvialableSupplier = async () => {
    setLoading(true);

    const { data = [], success } = await getAvailableBillerSuppliers();

    if (!!success && Array.isArray(data)) {
      setDistributorSuppliers(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    handleAllAvialableSupplier();
  }, []);

  const showSupplierList = !loading && !!distributorSuppliers.length;
  const showEmpty = !loading && !distributorSuppliers.length;

  return (
    <div className="home-dashboard-scree-available-suppliers-list">
      <div className="home-dashboard-screen-available-suppliers-text-container">
        <Text
          className="home-dashboard-screen-available-suppliers-text"
          style={{
            color: colors.textMidEmphasis
          }}>
          Available Suppliers
        </Text>
      </div>
      {loading && <Spin />}
      {showSupplierList && (
        <div className="home-dashboard-screen-scrollable-container">
          <div className="home-dashboard-screen-scrollable-container-1">
            {distributorSuppliers.map(distributorItem => (
              <DistributorSuppliertItem
                distributorItem={distributorItem}
                key={distributorItem?.name}
              />
            ))}
          </div>
        </div>
      )}
      {showEmpty && <Empty />}
    </div>
  );
};

export default DistributorSupplierList;
