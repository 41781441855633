import { logFirebaseEvent } from 'src/utils/firebase';
import { CLICK_MERCHANT } from 'src/constants/eventNames';

const clickMerchant = ({ otherParams = {} } = {}) => {
  logFirebaseEvent({
    eventName: CLICK_MERCHANT,
    eventParams: {
      ...otherParams
    }
  });
};

export default clickMerchant;
