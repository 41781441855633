import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import { logout } from 'src/utils/authorization';

const styles = {
  container: {
    border: '0.2px solid  #EFEFEF',
    width: '100%',
    height: 64,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px'
  },
  icon: {
    width: 'auto',
    height: 50
  },
  centerDiv: {
    flexGrow: 1,
    height: '100%'
  },
  logountIcon: {
    width: 30,
    height: 'auto'
  },
  button: {
    border: 0,
    backgroundColor: '#FFFFFF00',
    cursor: 'pointer'
  }
};

const GSNavBar = ({ children }) => {
  const logoutModal = () => {
    Modal.warning({
      title: 'Logout',
      content: (
        <div>
          <p>Are you sure you want to Logout?</p>
        </div>
      ),
      onOk: () => {
        logout();
      },
      okText: 'Yes',
      cancelText: 'No',
      okCancel: true
    });
  };

  return (
    <div style={styles.container}>
      <img alt="logo" src="./assets/logo/app-logo.png" style={styles.icon} />
      {children}
      <div style={styles.centerDiv} />
      <button style={styles.button} onClick={logoutModal} type="button">
        <img
          alt="log-out-button"
          src="./assets/icons/buttons/log-out-icon.png"
          style={styles.logountIcon}
        />
      </button>
    </div>
  );
};

GSNavBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func])
};

GSNavBar.defaultProps = {
  children: null
};

export default GSNavBar;
