import React, { useEffect } from 'react';
import { Spin, Typography, Modal } from 'antd';

import 'antd/dist/antd.css';
import AuthorizedRouters from 'src/components/routers/AuthorizedRouter';
import PublicRouter from 'src/components/routers/PublicRouter';
import { SimpleTemplate } from 'src/components/templates';

import { useAuthenticatingUser } from 'src/hooks/auth';
import { logout } from 'src/utils/authorization';
import { clearLocalStorage } from 'src/utils/localStorage';

import './App.css';

const App = () => {
  const { isAuthorized, isCheckingAuthorization, showUnauthorizedModal } = useAuthenticatingUser();

  useEffect(() => {
    if (showUnauthorizedModal) {
      clearLocalStorage();
      Modal.error({
        title: 'Session expired',
        onOk: () => {
          logout();
        }
      });
    }
  }, [showUnauthorizedModal]);

  if (isCheckingAuthorization) {
    return (
      <SimpleTemplate>
        <Spin />
        <Typography.Text>Loading...</Typography.Text>
      </SimpleTemplate>
    );
  }

  if (isAuthorized) {
    return <AuthorizedRouters />;
  }

  return <PublicRouter />;
};

export default App;
