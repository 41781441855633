import React from 'react';
import { Typography, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { RightOutlined } from '@ant-design/icons';
import { SimpleTemplate } from 'src/components/templates';
import { GSNavBar, GSButton } from 'src/components/common';
import './home-dashboard-screen.css';
import myWallet from 'src/utils/myWallet';
import services from 'src/constants/services';
import { getFormattedPesos } from 'src/utils/helpers';
import { colors } from 'src/styles';
import keys from 'src/constants';
import { getLocalStorage } from 'src/utils/localStorage';
import { HOME_DASHBOARD } from 'src/constants/pageNames';
import OFF_BOARDING_TEXTS from 'src/constants/offBoarding';
import config from 'src/config';
import { DistributorSupplierList } from './components';

const { Text } = Typography;
const { eListaServiceTypeIds, eListaServiceTypes } = services;
const hotline = services.HOTLINE;
const { PLAYSTORE_LINK = '' } = config;
const {
  OFF_BOARDING_TITLE = '',
  OFF_BOARDING_CONTENT = '',
  OFF_BOARDING_DOWNLOAD_BTN2
} = OFF_BOARDING_TEXTS;

const HomeDashboardScreen = () => {
  const {
    elistaCreditLimits,
    storeCreditDetails,
    loadingStoreCreditDetails,
    loadingElistaCreditLimits,
    loadingElistaCreditServices
  } = useSelector(state => state?.myWalletReducer);
  const isLoading =
    loadingElistaCreditLimits || loadingElistaCreditServices || loadingStoreCreditDetails;
  const { is_kyc_done: isKycDone, is_grow_credit_blocked: isGrowCreditBlocked } =
    storeCreditDetails;
  const mobileNumber = getLocalStorage({ key: keys.SIGNED_MOBILE_NUMBER });

  const { maxCreditLimit, availableLimit } = myWallet.getElistaCreditLimitByService({
    creditLimits: elistaCreditLimits,
    serviceGroup: eListaServiceTypes?.SERVICE_GROUP,
    type: eListaServiceTypeIds?.SERVICE_TYPE_PANINDA
  });

  const fomattedCreditLimit = getFormattedPesos(availableLimit, 2);
  const splitByDot = `${fomattedCreditLimit}`.split('.');
  const [integer, decimal] = splitByDot;
  const formattedMaxCreditLimit = getFormattedPesos(maxCreditLimit, 2);

  const isAllowedToElista = !!isKycDone && !isGrowCreditBlocked && !!Number(availableLimit);

  const renderMobileNumber = () => {
    return (
      !!mobileNumber && (
        <div className="home-dashboard-screen-mobile-number-container">
          <Text
            style={{ color: colors.textLowEmphasis }}
            className="home-dashboard-screen-my-number">
            My Number
          </Text>
          <Text style={{ color: colors.textHigh }} className="home-dashboard-screen-mobile-number">
            {mobileNumber}
          </Text>
        </div>
      )
    );
  };

  const isAllowedContents = !!(isAllowedToElista && !isLoading);
  const isUnallowedContents = !!(!isLoading && !isAllowedToElista);

  const renderAllowedContents = !!isAllowedContents && (
    <div className="home-dashboard-screen-container">
      <div className="home-dashboard-screen-wallet-container">
        <Text className="home-dashboard-screen-available-limit-text">Available Limit</Text>
        <div>
          <span className="home-dashboard-screen-integer">{integer}</span>
          <span className="home-dashboard-screen-cents">.{decimal}</span>
        </div>
        <span className="home-dashboard-screen-max-limit">Max of {formattedMaxCreditLimit}</span>
      </div>
      <DistributorSupplierList />
      <div style={{ marginTop: 16, marginBottom: 24 }}>
        <button
          href={`tel:${hotline}`}
          type="button"
          style={{ backgroundColor: 'inherit', borderWidth: 0 }}>
          <a
            href="https://www.facebook.com/SariPayPH/"
            target="_blank"
            rel="noreferrer noopener"
            style={{
              color: colors.primary,
              fontFamily: 'poppins',
              fontSize: 14,
              fontWeight: 600
            }}>
            {services.HOTLINE_HELP}
            <RightOutlined />
          </a>
        </button>
      </div>
    </div>
  );

  const renderUnallowedContents = isUnallowedContents && (
    <>
      <div className="home-dashboard-screen-no-elista-container">
        <img
          className="home-dashboard-scree-warning-icon"
          alt="no-elista"
          src="./assets/status/processing.png"
        />
        <Text className="home-dashboard-scree-no-elista-title">ELista not available</Text>
        <p className="home-dashboard-scree-no-elista-subtitle">
          Contact Call Center to apply for ELista
        </p>
      </div>
      <a
        href={services.FB_PAGE}
        target="_blank"
        rel="noreferrer noopener"
        style={{ margin: '16px 8px', width: '95%' }}>
        <GSButton
          href={services.FB_PAGE}
          label={services.HOTLINE_HELP}
          style={{ margin: 0, width: '95%' }}
        />
      </a>
    </>
  );

  const renderLoadingContent = isLoading && (
    <div className="home-dashboard-screen-no-elista-container">
      <Spin />
    </div>
  );

  const renderLeftIcon = () => (
    <img
      className="home-dashboard-download-icon"
      alt="download"
      src="./assets/icons/buttons/download-icon.png"
    />
  );

  const renderOffBoardingNudge = (
    <a
      href={PLAYSTORE_LINK}
      className="home-dashboard-offboarding-container"
      target="_blank"
      rel="noreferrer">
      <div className="home-dashboard-offboarding-title-container">
        <img
          className="home-dashboard-offboarding-icon"
          alt="download"
          src="./assets/logo/super-gigi-announcement.png"
        />
        <Text className="home-dashboard-offboarding-title">{OFF_BOARDING_TITLE}</Text>
      </div>
      <Text className="home-dashboard-offboarding-content">{OFF_BOARDING_CONTENT}</Text>
      <GSButton
        label={OFF_BOARDING_DOWNLOAD_BTN2?.label}
        leftIcon={renderLeftIcon}
        buttonStyles={OFF_BOARDING_DOWNLOAD_BTN2?.buttonStyles}
        labelStyles={OFF_BOARDING_DOWNLOAD_BTN2?.labelStyles}
      />
    </a>
  );

  return (
    <SimpleTemplate pageName={HOME_DASHBOARD}>
      <GSNavBar>{renderMobileNumber()}</GSNavBar>
      <div className="home-dashboard">
        {renderLoadingContent}
        {renderAllowedContents}
        {renderUnallowedContents}
        {renderOffBoardingNudge}
      </div>
    </SimpleTemplate>
  );
};

export default HomeDashboardScreen;
