import { message as antdMessage } from 'antd';

import config from 'src/config';
import { createAPISauceInstance } from 'src/utils/apiSauce';
import apiProxies from 'src/constants/apiProxies';
import endpoints from 'src/constants/endpoints';

const { HTTP_HEADER_1 } = config;

const getDistributorBillers = async () => {
  try {
    const response = await createAPISauceInstance({
      baseURL: apiProxies.bills_payment,
      headers: HTTP_HEADER_1,
      params: {
        categories: `["${'Distribution'}"]`
      }
    }).get(`${endpoints.BILLER_GET_DISTRIBUTOR_BILLER}`);

    const { data: responseData = {} } = response || {};
    const { success = false, data } = responseData || {};

    if (!success) {
      antdMessage.error(`Error get available biller suppliers: Unsuccessful`);
    }

    return {
      success,
      data
    };
  } catch (error) {
    antdMessage.error(`Error get available biller suppliers: ${error}`);

    return {
      success: false,
      data: null,
      errorMessage: error
    };
  }
};

export default getDistributorBillers;
