import React, { useEffect, useCallback } from 'react';
import { Routes, HashRouter, Route, Navigate } from 'react-router-dom';
import { EventRegister } from 'react-native-event-listeners';
import { useDispatch } from 'react-redux';

import { authorizedRouter } from 'src/navigation/routers';

import {
  getStoreCreditDetails,
  getStoreCreditServiceList,
  getCreditLimit
} from 'src/apis/myWallet';
import { actionUpdateMyWalletReducer } from 'src/redux/reducers/myWalletReducer';
import { RELOAD_MY_WALLET } from 'src/constants/reactEvents';

const AuthorizedRouters = () => {
  const dispatch = useDispatch();

  const handleStoreCreditLimit = useCallback(
    data => {
      dispatch(
        actionUpdateMyWalletReducer({ elistaCreditLimits: data, loadingElistaCreditLimits: false })
      );
    },
    [dispatch]
  );

  const handleStoreCreditServiceList = useCallback(
    data => {
      dispatch(
        actionUpdateMyWalletReducer({
          elistaCreditServices: data,
          loadingElistaCreditServices: false
        })
      );
    },
    [dispatch]
  );

  const handleStoreCreditDetails = useCallback(
    data => {
      dispatch(
        actionUpdateMyWalletReducer({ storeCreditDetails: data, loadingStoreCreditDetails: false })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    getStoreCreditDetails({
      onCompleted: handleStoreCreditDetails,
      dispatch
    });
    getStoreCreditServiceList({
      onCompleted: handleStoreCreditServiceList,
      dispatch
    });
    getCreditLimit({
      onCompleted: handleStoreCreditLimit,
      dispatch
    });
  }, [dispatch, handleStoreCreditDetails, handleStoreCreditLimit, handleStoreCreditServiceList]);

  useEffect(() => {
    let refreshWalletListener = null;
    refreshWalletListener = EventRegister.addEventListener(RELOAD_MY_WALLET, () => {
      getStoreCreditDetails({
        onCompleted: handleStoreCreditDetails,
        dispatch
      });
      getStoreCreditServiceList({
        onCompleted: handleStoreCreditServiceList,
        dispatch
      });
      getCreditLimit({
        onCompleted: handleStoreCreditLimit,
        dispatch
      });
    });

    return () => {
      EventRegister.removeEventListener(refreshWalletListener);
    };
  }, [dispatch, handleStoreCreditDetails, handleStoreCreditLimit, handleStoreCreditServiceList]);

  return (
    <HashRouter>
      <Routes>
        {authorizedRouter.map(({ element, path, caseSensitive }) => (
          <Route key={path} element={element} path={path} caseSensitive={caseSensitive} />
        ))}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </HashRouter>
  );
};

export default AuthorizedRouters;
