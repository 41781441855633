import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker, Typography } from 'antd';
import moment from 'moment';

import { colors } from 'src/styles';

const { Text } = Typography;

const DynamicCalendar = ({ field, setFormFieldValue }) => {
  const {
    label = null,
    default_value: dynamicPlaceholder,
    value = null,
    key,
    errorMessage = null
  } = field;

  const onChangeDate = (_, dateString) => {
    setFormFieldValue(prevValue => {
      const spreadPrevValue = [...prevValue];
      const findIndex = spreadPrevValue.findIndex(i => i.key === key);

      try {
        spreadPrevValue[findIndex].value = dateString;
      } catch {
        //
      }

      return spreadPrevValue;
    });
  };

  const status = errorMessage ? 'error' : null;
  const dateValue = value ? moment(value, 'YYYY-MM-DD') : null;

  return (
    <>
      {!!label && (
        <div style={{ textAlign: 'left' }}>
          <Text className="gs-text-input-label" style={{ color: colors.textHigh }}>
            {label}
          </Text>
        </div>
      )}
      <DatePicker
        style={{ width: '100%', height: 40, borderRadius: 4 }}
        placeholder={dynamicPlaceholder}
        onChange={onChangeDate}
        value={dateValue}
        status={status}
      />
      {errorMessage && (
        <div style={{ textAlign: 'left' }}>
          <Text className="gs-text-input-error">{errorMessage}</Text>
        </div>
      )}
    </>
  );
};

DynamicCalendar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object,
  setFormFieldValue: PropTypes.func
};

DynamicCalendar.defaultProps = {
  field: {},
  setFormFieldValue: () => {}
};

export default DynamicCalendar;
