import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  elistaCreditLimits: [],
  elistaCreditServices: [],
  storeCreditDetails: {},
  loadingStoreCreditDetails: true,
  loadingElistaCreditLimits: true,
  loadingElistaCreditServices: true
};

const myWalletReducer = createSlice({
  name: 'myWalletReducer',
  initialState: INITIAL_STATE,
  reducers: {
    actionUpdateMyWalletReducer: (state, action) => {
      const { payload = {} } = action;

      return { ...state, ...payload };
    },
    actionResetMyWalletReducer: () => {
      return INITIAL_STATE;
    }
  }
});

export const { actionResetMyWalletReducer, actionUpdateMyWalletReducer } = myWalletReducer.actions;

export default myWalletReducer.reducer;
