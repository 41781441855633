import { create } from 'apisauce';
import config from 'src/config';
import { getLocalStorage } from 'src/utils/localStorage';
import keys from 'src/constants';

const { GROWSARI_APP_HTTP_HEADERS } = config;

const createAPISauceInstance = ({ baseURL, headers = null, params, timeout, data }) => {
  const jwtToken = getLocalStorage({ key: keys.FMCG_TOKEN });

  const defaultHeader = {
    ...GROWSARI_APP_HTTP_HEADERS,
    Authorization: jwtToken
  };

  return create({
    withCredentials: false,
    baseURL: baseURL || '',
    params: params || {},
    headers: headers || defaultHeader || {},
    timeout: timeout || 30000,
    data: data || {}
  });
};

// eslint-disable-next-line import/prefer-default-export
export { createAPISauceInstance };
