import React from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';

import { publicRouter } from 'src/navigation/routers';

const PublicRouter = () => {
  return (
    <HashRouter>
      <Routes>
        {publicRouter.map(({ element, path, caseSensitive }) => (
          <Route key={path} element={element} path={path} caseSensitive={caseSensitive} />
        ))}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </HashRouter>
  );
};

export default PublicRouter;
