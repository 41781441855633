import React from 'react';
import PropTypes from 'prop-types';

import billers from 'src/constants/billers';
import { DynamicTextInput, DynamicCalendar, DynamicSpinner } from './components';

const { FORM_FIELD_TYPES } = billers;
const {
  //   Checkbox  clarify this to team
  Radio,
  Spinner,
  Image,
  DatePicker,
  Calendar,
  Text,
  Number,
  Phone
} = FORM_FIELD_TYPES;

const DynamicFormField = ({ formFields = [], setFormFieldValue = () => {} }) => {
  const renderItem = field => {
    const { type = '', is_web_hidden: isWebHidden = false } = field;

    if (isWebHidden) {
      return null;
    }

    switch (type.toLowerCase()) {
      case Number:
      case Phone:
      case Text: {
        return <DynamicTextInput field={field} setFormFieldValue={setFormFieldValue} />;
      }
      case DatePicker:
      case Calendar: {
        return <DynamicCalendar field={field} setFormFieldValue={setFormFieldValue} />;
      }
      case Image: {
        const { value } = field;

        return (
          <>
            <img src={value} alt="biller-img" />
            <pre>{JSON.stringify(field, null, 4)}</pre>
          </>
        );
      }
      case Radio:
      case Spinner: {
        return <DynamicSpinner field={field} setFormFieldValue={setFormFieldValue} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      {formFields.map(field => (
        <div key={field?.key} className="pay-dist-supp-form-screen-item-container">
          {renderItem(field)}
        </div>
      ))}
    </>
  );
};

DynamicFormField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formFields: PropTypes.array,
  setFormFieldValue: PropTypes.func
};

DynamicFormField.defaultProps = {
  formFields: [],
  setFormFieldValue: () => {}
};

export default DynamicFormField;
