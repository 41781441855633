const OTP_SUCCESS = 'otp_success';
const OTP_FAILED = 'otp_failed';
const VIEW_PAGE = 'view_page';
const CLICK_MERCHANT = 'click_merchant';
const DONE_INPUT_BILL_DETAILS = 'done_input_bill_details';
const PAY_DISTRIBUTOR = 'pay_distributor';

export {
  OTP_SUCCESS,
  OTP_FAILED,
  VIEW_PAGE,
  CLICK_MERCHANT,
  DONE_INPUT_BILL_DETAILS,
  PAY_DISTRIBUTOR
};
