import { logFirebaseEvent } from 'src/utils/firebase';
import { PAY_DISTRIBUTOR } from 'src/constants/eventNames';

const payDistributor = ({ otherParams = {} } = {}) => {
  logFirebaseEvent({
    eventName: PAY_DISTRIBUTOR,
    eventParams: {
      ...otherParams
    }
  });
};

export default payDistributor;
