const serviceNames = {
  Bayad: 'Bayad'
};

const eListaServiceTypeIds = {
  SERVICE_TYPE_PANINDA: '1',
  SERVICE_TYPE_ENEGOSYO: '2'
};

const eListaServiceTypes = {
  SERVICE: 'service',
  SERVICE_GROUP: 'service_group',
  OVERALL: 'overall'
};
const ELISTA_INTEREST_TYPES = {
  PERCENTAGE: 'PERCENTAGE',
  PESO: 'PESO'
};

const TRANSACTION_STATUS = {
  SUCCESSFUL: 'SUCCESSFUL',
  PROCESSING: 'PROCESSING',
  FAILURE: 'FAILURE'
};

const HOTLINE = '09190564444';

const HOTLINE_HELP = 'Need help?';

const FB_PAGE = 'https://www.facebook.com/SariPayPH/';

const services = {
  serviceNames,
  eListaServiceTypeIds,
  eListaServiceTypes,
  ELISTA_INTEREST_TYPES,
  TRANSACTION_STATUS,
  HOTLINE,
  HOTLINE_HELP,
  FB_PAGE
};

export default services;
