import { gql } from '@apollo/client';

const GENERATE_OTP = gql`
  mutation generateOtp($mobileNumber: String!) {
    response: generate_otp(mobile_number: $mobileNumber) {
      code
    }
  }
`;

const VERIFY_OTP = gql`
  mutation verifyOtp($code: String!, $mobileNumber: String!) {
    response: verify_otp(code: $code, mobile_number: $mobileNumber) {
      id
      name
      access_token
      id_token
      refresh_token
    }
  }
`;

const GET_PROFILE = gql`
  query getProfile {
    response: get_profile {
      id
      name
      store {
        id
        name
        address
        is_mobile_verified
        store_category {
          name
          payment_options
        }
      }
      fmcg_cookie
      fmcg_token
      fmcg_store_id
      fmcg_account_id
    }
  }
`;

export { GENERATE_OTP, VERIFY_OTP, GET_PROFILE };
