import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal, Typography, Button } from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';

import { getFormattedPesos } from 'src/utils/helpers';
import './payment-status-overlay.css';
import { GSButton } from 'src/components/common';
import billers from 'src/constants/billers';

const { Title, Text } = Typography;
const { TRANSACTION_STATUS } = billers;

const PaymentStatusOverlay = ({ visible, paymentData = {} }) => {
  const { state = {} } = useLocation();

  const navigate = useNavigate();
  const {
    created_at: createdAt,
    status,
    billAmount,
    provider_response_id: provResponseId
  } = paymentData;
  const { distributorItem } = state;
  const { name } = distributorItem || {};

  const onXmarkClick = () => {
    navigate('/', { replace: true });
  };

  const datePaid = moment().utc(createdAt).format('MMM DD, yyyy hh:mm A');
  const formatAmount = getFormattedPesos(billAmount, 2);
  const { message = '', CTA = '' } = TRANSACTION_STATUS?.[status] || {};

  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      closeIcon={false}
      closable={false}
      width="100%"
      bodyStyle={{
        height: '95vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        overflow: 'auto'
      }}
      style={{ maxWidth: '600px' }}>
      <div className="payment-status-overlay-container">
        <div style={{ width: '100%' }}>
          <Button style={{ backgroundColor: 'inherit', border: '0px' }} onClick={onXmarkClick}>
            <CloseOutlined style={{ color: '#000' }} />
          </Button>
        </div>
        <img className="payment-status-overlay-icon" src="./assets/status/success.png" alt="imge" />
        <Title className="payment-status-overlay-title">{message}</Title>
        <Text style={{ textAlign: 'center', marginBottom: 16 }}>REF NO. {provResponseId}</Text>
        <div className="payment-status-overlay-info-container">
          <Text>Show this to your supplier’s cashier/representative</Text>
        </div>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <div className="payment-status-overlay-detail-container">
            <Text className="payment-status-overlay-label">Paid to</Text>
            <Text>{name}</Text>
          </div>
          <div className="payment-status-overlay-detail-container">
            <Text className="payment-status-overlay-label">Date Paid</Text>
            <Text>{datePaid}</Text>
          </div>
          <div className="payment-status-overlay-detail-container">
            <Title className="payment-status-overlay-label" level={4}>
              Amount
            </Title>
            <Title style={{ margin: 0 }} level={3}>
              {formatAmount}
            </Title>
          </div>
        </div>
      </div>
      <GSButton onClick={onXmarkClick} buttonStyles={{ width: '100%' }} label={CTA} />
    </Modal>
  );
};

PaymentStatusOverlay.propTypes = {
  visible: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  paymentData: PropTypes.object
};

PaymentStatusOverlay.defaultProps = {
  visible: false,
  paymentData: {}
};

export default PaymentStatusOverlay;
