const getCensoredMobileNumber = stringMobileNumber => {
  try {
    const splitText = stringMobileNumber.split('');
    const indexToCensor = [4, 5, 6, 7];
    return splitText.map((i, index) => (indexToCensor.includes(index) ? '*' : i)).join('');
  } catch {
    return stringMobileNumber || '';
  }
};

const login = {
  getCensoredMobileNumber
};

export default login;
