const MARKETPLACE_TOKEN = 'MARKETPLACE_TOKEN';
const SIGNED_MOBILE_NUMBER = 'SIGNED_MOBILE_NUMBER';
const ACCOUNT_ID = 'ACCOUNT_ID';
const STORE_ID = 'STORE_ID';
const FMCG_COOKIE = 'FMCG_COOKIE';
const FMCG_TOKEN = 'FMCG_TOKEN';
const AUTH_STORE_STRING_OBJECT = 'AUTH_STORE_STRING_OBJECT';

const keys = {
  MARKETPLACE_TOKEN,
  SIGNED_MOBILE_NUMBER,
  ACCOUNT_ID,
  STORE_ID,
  FMCG_COOKIE,
  FMCG_TOKEN,
  AUTH_STORE_STRING_OBJECT
};

export default keys;
