import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { viewPage } from 'src/firebaseEvents';

const showTest = false;

const SimpleTemplate = ({ children, pageName = '' }) => {
  const { elistaCreditLimits, elistaCreditServices, storeCreditDetails } = useSelector(
    state => state.myWalletReducer
  );

  useEffect(() => {
    if (pageName) {
      viewPage({
        pageName
      });
    }
  }, [pageName]);

  return (
    <div className="App">
      {showTest && (
        <pre style={{ fontSize: 2 }}>
          {JSON.stringify(
            {
              env: process.env,
              myWalletReducer: {
                elistaCreditLimits: elistaCreditLimits?.[0] || null,
                elistaCreditServices: elistaCreditServices?.[0] || null,
                storeCreditDetails
              }
            },
            null,
            4
          )}
        </pre>
      )}
      {children}
    </div>
  );
};

SimpleTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
  pageName: PropTypes.string
};

SimpleTemplate.defaultProps = {
  children: () => null,
  pageName: ''
};

export default SimpleTemplate;
