import { getFormattedString, jsonParseObjectString } from './helpers';

const parseBillerForm = formFieldsStringObject => {
  const { views = [] } = jsonParseObjectString(formFieldsStringObject);
  return views;
};

const getBillPaymentStatus = ({ isError = false, isProcessing = false, callback = () => {} }) => {
  let displayTitle = 'Review Payment';
  let displayCTATitle = 'Pay';

  if (isError) {
    displayTitle = 'Transaction Failed';
    displayCTATitle = 'Back to Home';
  } else if (isProcessing) {
    displayTitle = 'Transaction Processing';
    displayCTATitle = 'Back to Home';
  }

  callback({ title: displayTitle, ctaTitle: displayCTATitle });
};

const getServiceName = (creditServices = [], billerName = '') => {
  const biller = getFormattedString(billerName);
  const creditService =
    creditServices.find(service => {
      const serviceName = getFormattedString(service.service_name);
      return biller.includes(serviceName) || serviceName.includes(biller);
    }) || {};

  return getFormattedString(creditService.service_name);
};

export { parseBillerForm, getBillPaymentStatus, getServiceName };
