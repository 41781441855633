import apiProxies from 'src/constants/apiProxies';
import { createAPISauceInstance } from 'src/utils/apiSauce';
import keys from 'src/constants';
import { getLocalStorage } from 'src/utils/localStorage';
import endpoints from 'src/constants/endpoints';
import { unauthorizedModalHandler } from 'src/utils/authorization';

const getCreditLimit = async ({ onCompleted = () => {}, dispatch, onError = null }) => {
  const accountId = getLocalStorage({ key: keys.ACCOUNT_ID });

  const response = await createAPISauceInstance({
    baseURL: apiProxies?.grow_credit,
    params: {
      account_id: accountId,
      timestamp: new Date().getTime()
    }
  }).get(endpoints.GROWCREDIT_GET_LIMITS);

  const { data: responseData } = response;
  const { data = [], success, code } = responseData;

  if (success && onCompleted) {
    onCompleted(data);
  } else {
    unauthorizedModalHandler({ dispatch, code, onError, response });
  }
};

export default getCreditLimit;
