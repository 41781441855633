const primary = '#FF6632';
const textHigh = '#262338';
const white = '#FFF';
const textMidEmphasis = '#4E4B66';
const borderSecondary = '#D9DBE9';
const borderTertiary = '#EFF0F6';
const textLowEmphasis = '#6E7191';
const bgNegative = '#E81C3A';

const colors = {
  primary,
  textHigh,
  white,
  textMidEmphasis,
  borderSecondary,
  borderTertiary,
  textLowEmphasis,
  bgNegative
};

export default colors;
