import { HomeDashboardScreen, PayDistributorSupplierFormScreen } from 'src/components/pages';

const authorizedRouter = [
  {
    path: '/',
    element: <HomeDashboardScreen />,
    caseSensitive: true
  },
  {
    path: '/pay_distributor_supplier_form',
    element: <PayDistributorSupplierFormScreen />,
    caseSensitive: true
  }
];

export default authorizedRouter;
