import { clearLocalStorage } from 'src/utils/localStorage';
import { actionUpdateAuthReducer } from 'src/redux/reducers/authReducer';
import { actionUpdateMyWalletReducer } from 'src/redux/reducers/myWalletReducer';

import api from 'src/constants/api';

const logout = () => {
  clearLocalStorage();
  window.location.reload(false);
};

const authorizeUser = ({ dispatch }) => {
  dispatch(actionUpdateAuthReducer({ isAuthorized: true, isCheckingAuthorization: false }));
};

const unauthorizedModalHandler = ({ dispatch, code, onError = null, response = {} } = {}) => {
  if (api.UNAUTHORIZED_CODES.includes(code) && dispatch) {
    dispatch(
      actionUpdateAuthReducer({
        showUnauthorizedModal: true
      })
    );
  } else if (onError) {
    onError(response);
  } else {
    dispatch(
      actionUpdateMyWalletReducer({
        loadingStoreCreditDetails: false,
        loadingElistaCreditLimits: false,
        loadingElistaCreditServices: false
      })
    );
  }
};

export { logout, authorizeUser, unauthorizedModalHandler };
