/* eslint-disable no-unreachable */
import React, { useCallback, useEffect, useState } from 'react';

import { Typography, message, Spin } from 'antd';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';

import { SimpleTemplate } from 'src/components/templates';
import { colors } from 'src/styles';
import { GSButton } from 'src/components/common';
import login from 'src/utils/login';
import { useVerifyOTP } from 'src/hooks/auth';
import { OTP_PAGE } from 'src/constants/pageNames';
import { OTPTimer, ResendOTPButton } from './components';

const { getCensoredMobileNumber } = login;

const { Text, Title } = Typography;

const pageTitle = 'Verify your account';

const subTitle = (censoredMobileNumber = '') => `Nagpadala kami ng one-time password 
    (OTP) sa ${getCensoredMobileNumber(censoredMobileNumber)}`;
const ctaVerifyOTP = 'Continue';
const noMobileNoError = `No Mobile number during OTP verification`;

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    padding: '0px 16px 0px 16px'
  },
  formContainer: {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 500,
    padding: '1em'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'column'
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    textAlign: 'left',
    margin: 0
  },
  subtitle: {
    fontFamily: 'Poppins',
    color: colors.textMidEmphasis,
    fontSize: 10,
    textAlign: 'left',
    margin: 0,
    fontWeight: 400
  },
  optInputContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    width: '100%'
  },
  otpInput: {
    border: 0,
    borderBottom: `2px solid ${colors.borderSecondary}`,
    width: '2em',
    fontSize: 18,
    fontFamily: 'poppins',
    fontWeight: 400
  },
  otpInputSeparator: {
    width: 8
  }
};

const VerifyOTPScreen = () => {
  const navigate = useNavigate();
  const { state = {} } = useLocation();
  const [otp, setOtp] = useState('');
  const { mobileNumber = null } = state || {};
  const [isResendOTP, setIsResentOTP] = useState(false);
  const { loadingVerifyOTP, runVerifyOTP } = useVerifyOTP({ mobileNumber: mobileNumber || '' });

  useEffect(() => {
    if (!mobileNumber) {
      message.error(noMobileNoError);
      navigate('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = otpInputValue => {
    setOtp(otpInputValue);
  };

  const onVerifyOtpPressed = useCallback(() => {
    runVerifyOTP({ mobileNumber, code: otp });
  }, [mobileNumber, otp, runVerifyOTP]);

  const onOTPTimerIsUp = useCallback(() => {
    setIsResentOTP(true);
  }, []);

  const onCompleteResentOTP = useCallback(() => {
    setIsResentOTP(false);
    message.success('OTP code resent');
  }, []);

  const isCTADisabled = !(otp && otp.length === 6);

  return (
    <SimpleTemplate pageName={OTP_PAGE}>
      <div style={styles.container}>
        <Spin spinning={loadingVerifyOTP}>
          <div style={styles.formContainer}>
            <div style={styles.titleContainer}>
              <Title style={styles.title} level={4}>
                {pageTitle}
              </Title>
              <Text style={styles.subtitle}>{subTitle(mobileNumber || '')}</Text>
              <OtpInput
                value={otp}
                onChange={onChange}
                numInputs={6}
                separator={<span style={styles.otpInputSeparator} />}
                containerStyle={styles.optInputContainer}
                inputStyle={styles.otpInput}
                isInputNum
              />
              {!isResendOTP && <OTPTimer seconds={300} onTimeIsUpCb={onOTPTimerIsUp} />}
              {isResendOTP && <ResendOTPButton onSuccessResendOTP={onCompleteResentOTP} />}
              <GSButton
                disabled={isCTADisabled}
                onClick={onVerifyOtpPressed}
                label={ctaVerifyOTP}
              />
            </div>
          </div>
        </Spin>
      </div>
    </SimpleTemplate>
  );
};

export default VerifyOTPScreen;
