const setLocalStorage = ({ key, stringValue }) => {
  if (key && stringValue) {
    localStorage.setItem(key, stringValue);
  }
};

const getLocalStorage = ({ key, defaultValue = null }) => {
  try {
    return localStorage.getItem(key) || defaultValue;
  } catch {
    return defaultValue;
  }
};

const removeLocalStorage = ({ key }) => {
  localStorage.removeItem(key);
};

const clearLocalStorage = () => {
  localStorage.clear();
};

export { getLocalStorage, setLocalStorage, clearLocalStorage, removeLocalStorage };
