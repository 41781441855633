/* eslint-disable no-console */
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';

import keys from 'src/constants';
import apiProxies from 'src/constants/apiProxies';
import config from 'src/config';

const errorLink = onError(({ graphQLErrors, networkError, ...restErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);

  if (restErrors) {
    console.log(' restGQL ERRORS: ', restErrors);
  }
});

const httpLink = createHttpLink({
  uri: apiProxies.marketplace
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(keys?.MARKETPLACE_TOKEN);

  return {
    headers: {
      ...headers,
      ...config.GROWSARI_MARKETPLACE_HTTP_HEADER,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const generateApolloLink = ApolloLink.from([authLink, errorLink, httpLink]);

const marketplaceClient = new ApolloClient({
  link: generateApolloLink,
  cache: new InMemoryCache()
});

export default marketplaceClient;
