import React from 'react';
import { Button } from 'antd';
import propTypes from 'prop-types';

import colors from 'src/styles/colors';

const { primary, white } = colors;

const styles = {
  loginButton: (buttonStyles, disabled) => ({
    marginTop: 48,
    backgroundColor: primary,
    borderRadius: 8,
    borderWidth: 0,
    cursor: 'pointer',
    width: '100%',
    height: '3em',
    ...buttonStyles,
    ...(disabled ? { opacity: 0.4 } : {})
  }),
  label: labelStyles => ({
    color: white,
    fontFamily: 'poppins',
    fontWeight: 700,
    fontSize: '0.9rem',
    ...labelStyles
  })
};

const GSButton = ({
  onClick,
  label,
  buttonStyles,
  labelStyles,
  disabled,
  leftIcon: LeftIcon = undefined
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      style={styles.loginButton(buttonStyles, disabled)}>
      {LeftIcon && <LeftIcon />}
      <div style={styles.label(labelStyles)}>{label}</div>
    </Button>
  );
};

GSButton.propTypes = {
  onClick: propTypes.func,
  label: propTypes.string,
  buttonStyles: propTypes.objectOf(propTypes.oneOfType([propTypes.string, propTypes.number])),
  labelStyles: propTypes.objectOf(propTypes.oneOfType([propTypes.string, propTypes.number])),
  disabled: propTypes.bool,
  leftIcon: propTypes.oneOfType([propTypes.element, propTypes.func])
};

GSButton.defaultProps = {
  onClick: () => {},
  label: 'button',
  buttonStyles: {},
  labelStyles: {},
  disabled: false,
  leftIcon: null
};

export default GSButton;
