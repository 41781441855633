import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message } from 'antd';

import { SimpleTemplate } from 'src/components/templates';
import './pay-dist-supp-form-screen.css';
import { GSBasicHeader, GSButton } from 'src/components/common';
import { parseBillerForm } from 'src/utils/billers';
import { DynamicFormFields } from 'src/components/organism';
import myWallet from 'src/utils/myWallet';
import services from 'src/constants/services';
import { getFormattedPesos } from 'src/utils/helpers';
import { colors } from 'src/styles';
import { getLocalStorage } from 'src/utils/localStorage';
import keys from 'src/constants';
import { doneInputBillDetails } from 'src/firebaseEvents';
import { ReviewPaymentFormOverlay } from './components';

const { eListaServiceTypeIds, eListaServiceTypes } = services;

const title = 'Enter Amount';

const PayDistributorSupplierFormScreen = () => {
  const { elistaCreditLimits } = useSelector(state => state?.myWalletReducer);
  const { state = {} } = useLocation();
  const [formInputs, setFormInputs] = useState([]);
  const [showReviewPaymentOverlay, setShowReviewPaymentOverlay] = useState(false);

  const { distributorItem } = state;
  const { form_fields: formFields, name, image, id } = distributorItem || {};

  const { availableLimit } = myWallet.getElistaCreditLimitByService({
    creditLimits: elistaCreditLimits,
    serviceGroup: eListaServiceTypes?.SERVICE_GROUP,
    type: eListaServiceTypeIds?.SERVICE_TYPE_PANINDA
  });

  const formattedAvailableLimit = getFormattedPesos(availableLimit, 2);
  const showForm = !!formInputs?.length;

  useEffect(() => {
    const views = parseBillerForm(formFields);
    const signMobileNumber = getLocalStorage({ key: keys.SIGNED_MOBILE_NUMBER });

    views.forEach((field, index) => {
      if (field.is_web_hidden) {
        views[index].value = signMobileNumber;
      }
    });
    setFormInputs(views);
  }, [formFields]);

  const { value: amount } = myWallet.getAmountInFormField({ formInputs });

  const isBalanceEnough = Number(amount) <= Number(availableLimit);

  useEffect(() => {
    setFormInputs(prevValue => {
      const spreadPrevValue = [...prevValue];
      const findIndex = spreadPrevValue.findIndex(i => i.key === 'amount');

      try {
        if (isBalanceEnough) {
          spreadPrevValue[findIndex].special_text = `Available limit: ${formattedAvailableLimit}`;
          spreadPrevValue[findIndex].special_text_styles = { color: colors.textMidEmphasis };
        } else {
          spreadPrevValue[findIndex].special_text = `Available limit: ${formattedAvailableLimit}`;
          spreadPrevValue[findIndex].special_text_styles = { color: colors.bgNegative };
        }
      } catch {
        //
      }

      return spreadPrevValue;
    });
  }, [availableLimit, formattedAvailableLimit, isBalanceEnough]);

  const onClickContinue = () => {
    let isAllowedToContinue = true;

    doneInputBillDetails({
      otherParams: {
        principal_amount: amount,
        biller_id: id
      }
    });

    if (Number(amount) > Number(availableLimit)) {
      message.warning('Credit limit not enough.');
      return;
    }

    const validatedInput = formInputs.map(field => {
      let isFieldHasError = false;
      const { required, value, error } = field;

      if (required) {
        isFieldHasError = !value;
      }

      if (isFieldHasError) {
        isAllowedToContinue = false;

        return { ...field, errorMessage: error || '* Required' };
      }

      return { ...field, errorMessage: null };
    });

    if (Number(amount) <= 0 || !Number(amount)) {
      message.warning('Amount must not below zero(0).');
      isAllowedToContinue = false;
    }

    if (isAllowedToContinue) {
      setFormInputs(validatedInput);
      setShowReviewPaymentOverlay(true);
    } else {
      setFormInputs(validatedInput);
    }
  };

  const onCloseReviewPaymentOverlay = () => {
    setShowReviewPaymentOverlay(false);
  };

  const isAllInputHaveValue = !!formInputs.every(i => !!`${i.value}`);
  const isCTAButtonEnabled = !isBalanceEnough || !isAllInputHaveValue;

  return (
    <SimpleTemplate>
      <div className="pay-dist-supp-form-screen-container">
        <GSBasicHeader title={title} />
        <div className="pay-dist-supp-form-screen-merchant-container">
          <img alt="merchant" src={image} className="pay-dist-supp-form-screen-icon" />
          <div className="pay-dist-supp-form-screen-merchant-name">{name}</div>
        </div>
        <div className="pay-dist-supp-form-screen-list-container">
          {showForm && (
            <DynamicFormFields formFields={formInputs} setFormFieldValue={setFormInputs} />
          )}
        </div>
        {showForm && (
          <GSButton
            disabled={isCTAButtonEnabled}
            buttonStyles={{ marginTop: 16 }}
            label="Continue"
            onClick={onClickContinue}
          />
        )}
      </div>
      {showReviewPaymentOverlay && (
        <ReviewPaymentFormOverlay
          visible
          onCloseModal={onCloseReviewPaymentOverlay}
          formInputs={formInputs}
        />
      )}
    </SimpleTemplate>
  );
};

export default PayDistributorSupplierFormScreen;
