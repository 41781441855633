const FORM_FIELD_TYPES = {
  Text: 'text',
  Phone: 'phone',
  Number: 'number',

  Radio: 'radio',
  Spinner: 'spinner',
  DatePicker: 'datepicker',
  Calendar: 'calendar',

  Checkbox: 'checkbox',
  Image: 'image'
};

const FORM_FIELD_KEYS = {
  amount: 'amount'
};

const CREDIT_SERVICE_KEY = 'Bayad';

const TRANSACTION_STATUS = {
  SUCCESSFUL: {
    CTA: 'Back to Home',
    message: 'Payment Successful'
  },
  PROCESSING: {
    CTA: 'Back to Home',
    message: 'Payment Processing'
  },
  FAILURE: 'FAILURE'
};

const TERMS_AND_CONDITION = {
  Bayad: {
    title: "By clicking 'Pay', you agree to the ",
    ctaTitle: 'Terms & Conditions'
  }
};

const billers = {
  FORM_FIELD_TYPES,
  FORM_FIELD_KEYS,
  CREDIT_SERVICE_KEY,
  TRANSACTION_STATUS,
  TERMS_AND_CONDITION
};

export default billers;
