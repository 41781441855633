import { useCallback, useState } from 'react';
import { message } from 'antd';

import { useMutation } from '@apollo/client';
import { GENERATE_OTP } from 'src/graphql/auth';

const useGenerateOTP = ({ onCompleted = () => {}, onError = () => {} }) => {
  const [loading, setLoading] = useState(false);

  const [generateOTP] = useMutation(GENERATE_OTP, {
    onCompleted: data => {
      const { response } = data;

      if (response) {
        if (onCompleted) {
          onCompleted();
        }
      }
      setLoading(false);
    },
    onError: error => {
      const { message: errMessage } = error;

      if (onError) {
        onError(errMessage);
      }

      message.error(errMessage);
      setLoading(false);
    }
  });

  const runGenerateOTP = useCallback(
    ({ mobileNumber = null }) => {
      setLoading(true);

      generateOTP({
        variables: {
          mobileNumber
        }
      });
    },
    [generateOTP]
  );

  return {
    runGenerateOTP,
    loadingGenerateOTP: loading
  };
};

export default useGenerateOTP;
