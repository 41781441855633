import React, { useEffect, useState } from 'react';
import { Modal, Typography, Spin, message as antdMessage } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventRegister } from 'react-native-event-listeners';

import { GSBasicHeader, GSButton } from 'src/components/common';
import billers from 'src/constants/billers';
import { getFormattedPesos, getFormattedNumber } from 'src/utils/helpers';
import { getCreditBillingDate } from 'src/apis/myWallet';
import myWallet from 'src/utils/myWallet';
import { generateOrderId, getBaseProcessingRate } from 'src/utils/eservices';
import keys from 'src/constants';
import { getLocalStorage } from 'src/utils/localStorage';
import services from 'src/constants/services';
import { postPayBill } from 'src/apis/billers';
import { RELOAD_MY_WALLET } from 'src/constants/reactEvents';
import { getBillPaymentStatus, getServiceName } from 'src/utils/billers';
import { colors } from 'src/styles';
import { payDistributor } from 'src/firebaseEvents';
import PaymentStatusOverlay from './PaymentStatusOverlay';
import TermsAndConditionOverlay from './TermsAndConditionOverlay';

import './review-payment-form-overlay.css';

const { Text, Title } = Typography;

const { eListaServiceTypeIds, eListaServiceTypes } = services;

const ReviewPaymentFormScreen = ({ visible = false, onCloseModal = () => {}, formInputs = [] }) => {
  const { elistaCreditLimits, elistaCreditServices } = useSelector(state => state?.myWalletReducer);
  const { state: locationState = {} } = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [billingDate, setBillingDate] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [isTransacting, setIsTransacting] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [invouceDetails, setInvoiceDetails] = useState(false);
  const [isToHomePage, setIsToHomePage] = useState(false);
  const [displayTitle, setDisplayTitle] = useState('Review Payment');
  const [displayCTATitle, setDisplayCTATitle] = useState('Pay');
  const [isShowTNCModal, setIsShowTNCModal] = useState(false);

  const { distributorItem = {} } = locationState;
  const {
    id: billerId,
    pass_on_cost: passOnCost = 0,
    pass_on_cost_type: passOnCostType,
    name,
    image
  } = distributorItem;
  const { value: amount } = myWallet.getAmountInFormField({ formInputs });
  const { interest_type: interestType, credit_interest: creditInterest } =
    elistaCreditServices.find(i => i.service_name === billers.CREDIT_SERVICE_KEY) || {};
  const elistaProcessingFee = myWallet.getProcessingFee({
    amount,
    creditInterest,
    interestType
  });
  const billerProcessingFee = myWallet.getProcessingFee({
    amount,
    creditInterest: passOnCost,
    interestType: passOnCostType
  });

  const formattedAmount = getFormattedPesos(amount, 2);
  const formattedProcessingFee = getFormattedPesos(elistaProcessingFee, 2);
  const formatPassOnCost = getFormattedPesos(billerProcessingFee, 2);
  const totalAmount = getFormattedPesos(
    getFormattedNumber(amount, 2, true) +
      getFormattedNumber(elistaProcessingFee, 2, true) +
      getFormattedNumber(billerProcessingFee, 2, true),
    2,
    false
  );
  const { availableLimit } = myWallet.getElistaCreditLimitByService({
    creditLimits: elistaCreditLimits,
    serviceGroup: eListaServiceTypes?.SERVICE_GROUP,
    type: eListaServiceTypeIds?.SERVICE_TYPE_PANINDA
  });
  const formatBillingDate = moment(billingDate).format('MMM DD, yyyy');
  const elistaInteresProcessingRate = getBaseProcessingRate(creditInterest, interestType);
  const billerInteresProcessingRate = getBaseProcessingRate(passOnCost, passOnCostType);

  useEffect(() => {
    setClientId(generateOrderId());
    setIsLoading(true);
    getCreditBillingDate({
      onCompleted: data => {
        const { expected_billing_date: creditBillingDate } = data;
        setBillingDate(creditBillingDate || null);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      }
    });

    return () => {
      antdMessage.destroy('processing_pay_bill');
      antdMessage.destroy('failed_pay_bill');
    };
  }, []);

  const navigateToHomeScreen = () => {
    navigate('/', { replace: true });
  };

  const onCloseModalClick = () => {
    if (isToHomePage) {
      navigateToHomeScreen();

      return;
    }

    onCloseModal();
  };

  const onClickPay = () => {
    if (isToHomePage) {
      navigateToHomeScreen();

      return;
    }

    if (isTransacting) {
      return;
    }

    setIsTransacting(true);
    const accountId = getLocalStorage({ key: keys.ACCOUNT_ID });
    const signedUserMobileNumber = getLocalStorage({ key: keys.SIGNED_MOBILE_NUMBER });

    const reduceFormInputs = formInputs.reduce((base, field) => {
      const { key, value } = field;

      return { ...base, [key]: value };
    }, {});

    const payApiParams = {
      account_id: accountId,
      biller_id: billerId,
      client_id: clientId,
      comms_options: [
        {
          comms_type: 'SMS',
          cost: '0.00',
          value: signedUserMobileNumber
        }
      ],
      credit_amount: Number(availableLimit),
      pass_on_cost: billerProcessingFee,
      use_credit: true,
      validation_token: undefined,
      is_web: true,
      ...reduceFormInputs
    };

    postPayBill({
      formdata: payApiParams,
      onCompleted: (paymentData, status) => {
        EventRegister.emit(RELOAD_MY_WALLET);

        setInvoiceDetails({ ...paymentData, billAmount: amount });
        setShowStatus(true);
        setIsTransacting(false);
        antdMessage.success(`Payment: ${status}`);
        payDistributor({
          otherParams: {
            principal_amount: amount,
            status,
            biller_id: billerId
          }
        });
      },
      onError: errorMessage => {
        EventRegister.emit(RELOAD_MY_WALLET);

        const callback = ({ title, ctaTitle }) => {
          setDisplayCTATitle(ctaTitle);
          setDisplayTitle(title);
          setIsToHomePage(true);
          setIsTransacting(false);
          payDistributor({
            otherParams: {
              principal_amount: amount,
              status: 'FAILED',
              biller_id: billerId
            }
          });

          const messageConfig = {
            key: 'failed_pay_bill',
            type: 'error',
            content: `Payment Error: ${errorMessage}`,
            duration: 0
          };

          antdMessage.open(messageConfig);
        };

        getBillPaymentStatus({
          isError: true,
          callback
        });
      },
      onTimeout: timeoutMessage => {
        EventRegister.emit(RELOAD_MY_WALLET);

        const callback = ({ title, ctaTitle }) => {
          setDisplayCTATitle(ctaTitle);
          setDisplayTitle(title);
          setIsToHomePage(true);
          setIsTransacting(false);

          const messageConfig = {
            key: 'processing_pay_bill',
            type: 'warning',
            content: `${timeoutMessage}`,
            duration: 0
          };
          payDistributor({
            otherParams: {
              principal_amount: amount,
              status: 'PROCESSING',
              biller_id: billerId
            }
          });

          antdMessage.open(messageConfig);
        };

        getBillPaymentStatus({
          isProcessing: true,
          callback
        });
      }
    });
  };

  const onCloseTNCModal = () => {
    setIsShowTNCModal(false);
  };

  const onShowTNCModal = () => {
    setIsShowTNCModal(true);
  };

  const onAcceptTAC = () => {
    onCloseTNCModal();
  };
  const onDeclineTAC = () => {
    onCloseTNCModal();
  };

  const isSpinning = isLoading || isTransacting;

  if (showStatus) {
    return <PaymentStatusOverlay visible paymentData={invouceDetails} />;
  }

  const merchantLabel = (formInputs?.[1]?.label || '').replace(/choose/gi, '');
  const merchantValue = formInputs?.[1].value;

  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      closeIcon={false}
      closable={false}
      width="100%"
      bodyStyle={{ padding: 0 }}
      style={{ maxWidth: '600px' }}>
      <div className="review-payment-form-overlay-container">
        <Spin spinning={isSpinning}>
          <GSBasicHeader title={displayTitle} customOnBackClick={onCloseModalClick} />
          <div className="review-payment-form-overlay-merchant-container">
            <img alt="merchant" src={image} className="review-payment-form-overlay-merchant-img" />
            <div className="review-payment-form-overlay-merchant-text">{name}</div>
            <div>
              {merchantLabel}: {merchantValue}
            </div>
          </div>
          {!!amount && (
            <div className="review-payment-form-overlay-text-container">
              <Text className="review-payment-form-overlay-label-item">Amount </Text>
              <Text>{formattedAmount}</Text>
            </div>
          )}
          {!!billerProcessingFee && (
            <div className="review-payment-form-overlay-text-container">
              <Text className="review-payment-form-overlay-label-item">
                Processing Fee ({billerInteresProcessingRate})
              </Text>
              <Text>+ {formatPassOnCost}</Text>
            </div>
          )}
          {!!elistaProcessingFee && (
            <div className="review-payment-form-overlay-text-container">
              <Text className="review-payment-form-overlay-label-item">
                ELista Processing Fee ({elistaInteresProcessingRate})
              </Text>
              <Text>+ {formattedProcessingFee}</Text>
            </div>
          )}
          <div className="review-payment-form-overlay-text-container">
            <div className="review-payment-form-overlay-label-item">
              <div>
                <Title className="review-payment-form-overlay-title" level={5}>
                  Total
                </Title>
              </div>
              <div>
                <Text className="review-payment-form-overlay-due">Due on {formatBillingDate} </Text>
              </div>
            </div>
            <Title className="review-payment-form-overlay-total" level={2}>
              {totalAmount}
            </Title>
          </div>
          {!isToHomePage && (
            <div className="review-payment-form-overlay-tnc-container">
              <Text className="review-payment-form-overlay-tnc-text-1">
                {billers.TERMS_AND_CONDITION.Bayad.title}
                <button
                  onClick={onShowTNCModal}
                  style={{ color: colors.primary }}
                  className="review-payment-form-overlay-tnc-button-1"
                  type="button">
                  {billers.TERMS_AND_CONDITION.Bayad.ctaTitle}
                </button>
              </Text>
            </div>
          )}
          <GSButton label={displayCTATitle} onClick={onClickPay} />
        </Spin>
      </div>
      <TermsAndConditionOverlay
        onCloseModal={onCloseTNCModal}
        visible={isShowTNCModal}
        onAccept={onAcceptTAC}
        onDecline={onDeclineTAC}
        serviceName={getServiceName(elistaCreditServices, name)}
      />
    </Modal>
  );
};

ReviewPaymentFormScreen.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  formInputs: PropTypes.array
};

ReviewPaymentFormScreen.defaultProps = {
  visible: false,
  onCloseModal: () => {},
  formInputs: []
};

export default ReviewPaymentFormScreen;
