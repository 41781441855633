import { logFirebaseEvent } from 'src/utils/firebase';
import { VIEW_PAGE } from 'src/constants/eventNames';

const viewPage = ({ pageName = '', otherParams = {} } = {}) => {
  logFirebaseEvent({
    eventName: VIEW_PAGE,
    eventParams: {
      page_name: pageName,
      ...otherParams
    }
  });
};

export default viewPage;
