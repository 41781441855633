import { logFirebaseEvent } from 'src/utils/firebase';
import { DONE_INPUT_BILL_DETAILS } from 'src/constants/eventNames';

const doneInputBillDetails = ({ otherParams = {} } = {}) => {
  logFirebaseEvent({
    eventName: DONE_INPUT_BILL_DETAILS,
    eventParams: {
      ...otherParams
    }
  });
};

export default doneInputBillDetails;
