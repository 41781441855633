import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Spin, message, Modal } from 'antd';

import { GSTextInput, GSButton } from 'src/components/common';
import { SimpleTemplate } from 'src/components/templates';
import { useGenerateOTP } from 'src/hooks/auth';
import { LOGIN_PAGE } from 'src/constants/pageNames';
import OFF_BOARDING_TEXTS from 'src/constants/offBoarding';
import config from 'src/config';
import './login-screen.css';

const { Title, Text } = Typography;
const {
  OFF_BOARDING_TITLE,
  OFF_BOARDING_CONTENT,
  OFF_BOARDING_DOWNLOAD_BTN1,
  OFF_BOARDING_DOWNLOAD_LATER
} = OFF_BOARDING_TEXTS;
const { PLAYSTORE_LINK } = config;

const message1 = 'Login using your registered mobile number';
const ctaText = 'Login';
const inputMobileNoPlaceholder = 'Enter you 11 digit mobile number';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    padding: '0px 16px 0px 16px'
  },
  formContainer: {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 500,
    padding: '16px'
  },
  logo: {
    height: 'auto',
    width: '20vw',
    minWidth: 150,
    maxWidth: 200,
    marginBottom: 24
  },
  title: {
    marginBottom: 24,
    fontFamily: 'Poppins',
    fontWeight: 600
  },
  loginButton: {
    marginTop: 48
  }
};

const App = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [openModal, setOpenModal] = useState(true);

  const onCompleted = useCallback(() => {
    message.success('Login Successful');

    navigate('/verify-otp', { state: { mobileNumber } });
  }, [mobileNumber, navigate]);

  const { runGenerateOTP, loadingGenerateOTP } = useGenerateOTP({
    onCompleted
  });

  const onChangeMobileNumber = useCallback(text => {
    setMobileNumber(text);
  }, []);

  const onPressLoginButton = useCallback(() => {
    if (!mobileNumber) {
      message.warning('Invalid Mobile Number');
      return;
    }

    runGenerateOTP({ mobileNumber });
  }, [mobileNumber, runGenerateOTP]);

  const handleDownload = () => {
    setOpenModal(false);
    window.open(PLAYSTORE_LINK, '_blank');
  };

  const handleDownloadLater = () => setOpenModal(false);

  return (
    <SimpleTemplate pageName={LOGIN_PAGE}>
      <div style={styles.container}>
        <Spin spinning={loadingGenerateOTP}>
          <div style={styles.formContainer}>
            <img alt="logo" src="./assets/logo/app-logo.png" style={styles.logo} />
            <Title level={5} style={styles.title}>
              {message1}
            </Title>
            <GSTextInput
              onChange={onChangeMobileNumber}
              placeholder={inputMobileNoPlaceholder}
              value={mobileNumber}
            />
            <GSButton
              onClick={onPressLoginButton}
              label={ctaText}
              buttonStyles={styles.loginButton}
            />
          </div>
        </Spin>
        <Modal open={openModal} footer={null} closable={false}>
          <img
            className="login-offboarding-icon"
            alt="download"
            src="./assets/logo/super-gigi-announcement.png"
          />
          <Text className="login-offboarding-title">{OFF_BOARDING_TITLE}</Text>
          <Text className="login-offboarding-content">{OFF_BOARDING_CONTENT}</Text>
          <GSButton
            onClick={handleDownload}
            label={OFF_BOARDING_DOWNLOAD_BTN1?.label}
            buttonStyles={OFF_BOARDING_DOWNLOAD_BTN1?.buttonStyles}
            labelStyles={OFF_BOARDING_DOWNLOAD_BTN1?.labelStyles}
          />
          <GSButton
            onClick={handleDownloadLater}
            label={OFF_BOARDING_DOWNLOAD_LATER?.label}
            buttonStyles={OFF_BOARDING_DOWNLOAD_LATER?.buttonStyles}
            labelStyles={OFF_BOARDING_DOWNLOAD_LATER?.labelStyles}
          />
        </Modal>
      </div>
    </SimpleTemplate>
  );
};

export default App;
