import apiProxies from 'src/constants/apiProxies';
import { createAPISauceInstance } from 'src/utils/apiSauce';
import keys from 'src/constants';
import { getLocalStorage } from 'src/utils/localStorage';
import endpoints from 'src/constants/endpoints';
import { unauthorizedModalHandler } from 'src/utils/authorization';

const getStoreCreditDetails = async ({
  onCompleted = () => {},
  dispatch = null,
  onError = null
}) => {
  try {
    const accountId = getLocalStorage({ key: keys.ACCOUNT_ID });
    const response = await createAPISauceInstance({
      baseURL: apiProxies?.grow_credit,
      params: {
        account_ids: `[${accountId}]`
      }
    }).get(endpoints.GROWCREDIT_GET_CREDIT_DETAIL);

    const { data: responseData } = response || {};
    const { data = [], code, success } = responseData;

    if (success && onCompleted) {
      onCompleted(data?.[0] || {});
    } else {
      unauthorizedModalHandler({ dispatch, code, onError, response });
    }
  } catch {
    //
  }
};

export default getStoreCreditDetails;
