import { useCallback, useState, useRef } from 'react';

import { useMutation } from '@apollo/client';
import { message as antdMessage } from 'antd';
import keys from 'src/constants';

import { VERIFY_OTP } from 'src/graphql/auth';
import { otpFailed, otpSuccess } from 'src/firebaseEvents';
import useAuthGetProfile from './useAuthGetProfile';

const useVerifyOTP = ({ mobileNumber: userMobileNumber = '' } = {}) => {
  const mobileNumberRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const { runGetAuthProfile } = useAuthGetProfile();

  const [verifyOTP] = useMutation(VERIFY_OTP, {
    onCompleted: data => {
      const { response } = data;
      const { access_token: accessToken } = response;
      otpSuccess({
        otherParams: {
          mobile_number: userMobileNumber || ''
        }
      });
      localStorage.setItem(keys.MARKETPLACE_TOKEN, accessToken);
      localStorage.setItem(keys.SIGNED_MOBILE_NUMBER, mobileNumberRef.current || '');
      antdMessage.info('OTP is Valid');

      runGetAuthProfile();

      setLoading(false);
    },
    onError: error => {
      const { message, graphQLErrors, networkError } = error;
      otpFailed({
        otherParams: {
          mobile_number: userMobileNumber || ''
        }
      });
      setLoading(false);

      if (networkError) {
        antdMessage.error(`Network Error Message: ${JSON.stringify(networkError, null, 2)}`);
      } else if (graphQLErrors && graphQLErrors[0]) {
        const { message: errMsg = 'Cannot Proceed request, please retry logging-in.' } =
          graphQLErrors[0] || {};

        if (message === 'Invalid OTP') {
          antdMessage.warning(message);
        } else {
          antdMessage.error(errMsg);
        }
      } else {
        antdMessage.warning(`Invalid OTP: You've entered a wrong OTP.`);
      }
    }
  });

  const runVerifyOTP = useCallback(
    ({ code, mobileNumber }) => {
      setLoading(true);
      mobileNumberRef.current = mobileNumber || null;
      verifyOTP({
        variables: {
          code,
          mobileNumber
        }
      });
    },
    [verifyOTP]
  );

  return {
    runVerifyOTP,
    loadingVerifyOTP: loading
  };
};

export default useVerifyOTP;
