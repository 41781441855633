import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

import config from 'src/config';
import { getLocalStorage } from 'src/utils/localStorage';
import keys from 'src/constants/localStorage';

const { SHOULD_RUN_FIREBASE } = config;

const initializedFirebaseApp = initializeApp(config.FIREBASE_CONFIG);

const analyticsInstance = getAnalytics(initializedFirebaseApp);

const logFirebaseEvent = ({ eventName = '', eventParams = {} } = {}) => {
  if (SHOULD_RUN_FIREBASE) {
    try {
      const accountId = getLocalStorage({ key: keys.ACCOUNT_ID });
      const storeId = getLocalStorage({ key: keys.STORE_ID });
      const timestamp = Math.floor(Date.now() / 1000);
      const defaultParams = {
        ...(accountId ? { account_id: accountId } : undefined),
        ...(accountId ? { store_id: storeId } : undefined),
        timestamp,
        ...eventParams
      };

      logEvent(analyticsInstance, eventName, defaultParams);
    } catch {
      // NO TODO
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { logFirebaseEvent };
