import { useState } from 'react';
import { message as antdMessage } from 'antd';

import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GET_PROFILE } from 'src/graphql/auth';
import { setLocalStorage } from 'src/utils/localStorage';
import keys from 'src/constants';
import { authorizeUser } from 'src/utils/authorization';

const successMessage1 = storeName => `Welcome, ${storeName}`;
const errorMessage1 = message => `User profile error: ${message}`;

const useAuthGetProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [getAuthProfile] = useLazyQuery(GET_PROFILE, {
    onCompleted: data => {
      const { response } = data;
      const {
        fmcg_account_id: accountId,
        fmcg_store_id: storeId,
        fmcg_cookie: fmcgCookie,
        fmcg_token: fmcgToken,
        store = {}
      } = response;
      const storeName = store?.name || '';
      setLocalStorage({ key: keys.ACCOUNT_ID, stringValue: accountId });
      setLocalStorage({ key: keys.STORE_ID, stringValue: storeId });
      setLocalStorage({ key: keys.FMCG_COOKIE, stringValue: fmcgCookie });
      setLocalStorage({ key: keys.FMCG_TOKEN, stringValue: fmcgToken });
      setLocalStorage({ key: keys.AUTH_STORE_STRING_OBJECT, stringValue: JSON.stringify(store) });
      antdMessage.success(successMessage1(storeName));
      authorizeUser({ dispatch });

      navigate('/', { replace: true });
      setLoading(false);
    },
    onError: error => {
      const { message } = error;
      antdMessage.error(errorMessage1(message));
      setLoading(false);
    },
    fetchPolicy: 'no-cache'
  });

  const runGetAuthProfile = () => {
    getAuthProfile();
  };

  return {
    getAuthProfileLoading: loading,
    runGetAuthProfile
  };
};

export default useAuthGetProfile;
