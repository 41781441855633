const BUILD_ENV = 'PROD';
const BUILD_VERSION = '8.7.1';
const BUILD_CODE = 185;
const APP = 'store';
const GROWSARI_APP_HTTP_HEADERS = {
  'x-growsari-app': APP
};
const BUILD_DATE = '20231016';
const APP_KEY = 'a821a809aa1d82286a096ea6405201cd7cd0ca3c465e314147cd9ed8d825c15e';
const APP_SECRET = '12262e3c25ccb29fd0597b377c6cf634158379edca3ab02bd83b9f4c83293ecf';
const HTTP_HEADER_1 = {
  'x-growsari-app': APP,
  'app-key': APP_KEY,
  'app-secret': APP_SECRET
};
const PAY_BILL_APP_KEY = '0LnfjXd018wY35bzwI0yUwpL5Rr4huDa';
const PAY_BILL_APP_SECRET = '189UukBzrkvkAnG5k4xc3Og5T09JP4NE';
const HTTP_PAY_BILL_HEADER_1 = {
  'app-key': PAY_BILL_APP_KEY,
  'app-secret': PAY_BILL_APP_SECRET,
  'x-growsari-app': APP
};
const GROWSARI_MARKETPLACE_HTTP_HEADER = {
  'x-growsari-version': BUILD_VERSION,
  'x-growsari-version-code': BUILD_CODE,
  'x-growsari-app': APP
};
const MARKETPLACE_GQL_BASE_URL = `https://z9f5u8qp77.execute-api.ap-southeast-1.amazonaws.com/prd/graphql`;
const BILLS_PAYMENT_BASE_URL = `https://bayad.growsari.com/api/v1/`;
const GROWCREDIT_BASE_URL = `https://growcredit.growsari.com/api/`;

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAwPhl4n2_PDkjsR1dlTnsLFCH3rGFGYR8',
  authDomain: 'saripay-73f4c.firebaseapp.com',
  projectId: 'saripay-73f4c',
  storageBucket: 'saripay-73f4c.appspot.com',
  messagingSenderId: '245429472856',
  appId: '1:245429472856:web:f8c0ca0d57c6146781c42d',
  measurementId: 'G-19ZB6SGBHH'
};

const TERMS_CONDITIONS_URL = 'https://saripay.com/#/elistatermsandconditons';

const SHOULD_RUN_FIREBASE = true;

const PLAYSTORE_LINK = 'https://play.google.com/store/apps/details?id=com.growsari.saripay&pli=1';

const config = {
  BUILD_ENV,
  BUILD_VERSION,
  BUILD_CODE,
  APP,
  GROWSARI_APP_HTTP_HEADERS,
  BUILD_DATE,
  APP_KEY,
  APP_SECRET,
  HTTP_HEADER_1,
  PAY_BILL_APP_KEY,
  PAY_BILL_APP_SECRET,
  HTTP_PAY_BILL_HEADER_1,
  GROWSARI_MARKETPLACE_HTTP_HEADER,
  MARKETPLACE_GQL_BASE_URL,
  BILLS_PAYMENT_BASE_URL,
  GROWCREDIT_BASE_URL,
  FIREBASE_CONFIG,
  TERMS_CONDITIONS_URL,
  SHOULD_RUN_FIREBASE,
  PLAYSTORE_LINK
};

export default config;
