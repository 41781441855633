import config from 'src/config';

const { GROWCREDIT_BASE_URL, MARKETPLACE_GQL_BASE_URL, BILLS_PAYMENT_BASE_URL } = config;

const apiBaseUrlProxies = {
  marketplace: MARKETPLACE_GQL_BASE_URL,
  bills_payment: BILLS_PAYMENT_BASE_URL,
  grow_credit: GROWCREDIT_BASE_URL
};

export default apiBaseUrlProxies;
