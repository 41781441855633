import React from 'react';
import PropTypes from 'prop-types';

import { GSTextInput } from 'src/components/common';
import billers from 'src/constants/billers';

const { FORM_FIELD_TYPES } = billers;
const { Number, Phone } = FORM_FIELD_TYPES;

const DynamicTextInput = ({ field = {}, setFormFieldValue = () => {} }) => {
  const {
    label = null,
    default_value: dynamicPlaceholder,
    required,
    value = null,
    key,
    max_length: maxLength,
    errorMessage = null,
    type,
    special_text: specialText = null,
    special_text_styles: specialTextStyles = {}
  } = field;

  const inputType =
    key === 'amount' || type.toLowerCase() === Number || type.toLowerCase() === Phone
      ? 'number'
      : 'default';

  const onChangeValue = text => {
    setFormFieldValue(prevValue => {
      const spreadPrevValue = [...prevValue];
      const findIndex = spreadPrevValue.findIndex(i => i.key === key);

      try {
        spreadPrevValue[findIndex].value = text;
      } catch {
        //
      }

      return spreadPrevValue;
    });
  };

  return (
    <GSTextInput
      placeholder={dynamicPlaceholder}
      label={label}
      required={required}
      onChange={onChangeValue}
      value={value}
      maxLength={maxLength}
      errorMessage={errorMessage}
      inputType={inputType}
      specialText={specialText}
      specialTextStyles={specialTextStyles}
    />
  );
};

DynamicTextInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object,
  setFormFieldValue: PropTypes.func
};

DynamicTextInput.defaultProps = {
  field: {},
  setFormFieldValue: () => {}
};

export default DynamicTextInput;
