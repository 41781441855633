import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actionUpdateAuthReducer } from 'src/redux/reducers/authReducer';
import { getLocalStorage } from 'src/utils/localStorage';
import keys from 'src/constants';

const useAuthenticatingUser = () => {
  const dispatch = useDispatch();
  const { isAuthorized, isCheckingAuthorization, showUnauthorizedModal } = useSelector(
    state => state?.authReducer
  );
  const isAuthorizingRef = useRef(false);

  useEffect(() => {
    if (!isAuthorizingRef.current) {
      isAuthorizingRef.current = true;
      const marketplaceToken = getLocalStorage({ key: keys.MARKETPLACE_TOKEN });
      const isHasAuthorization = !!marketplaceToken;
      if (isHasAuthorization) {
        dispatch(actionUpdateAuthReducer({ isAuthorized: true, isCheckingAuthorization: false }));
      } else {
        dispatch(actionUpdateAuthReducer({ isAuthorized: false, isCheckingAuthorization: false }));
      }
    }
  }, [dispatch]);

  return { isAuthorized, isCheckingAuthorization, showUnauthorizedModal };
};

export default useAuthenticatingUser;
