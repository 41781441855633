import React from 'react';

import { Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Worker, Viewer } from '@react-pdf-viewer/core';

import { CloseCircleOutlined } from '@ant-design/icons';
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Title } = Typography;

const TermsAndConditionOverlay = ({
  onCloseModal = () => {},
  visible = false,
  serviceName = ''
}) => {
  const filePath = `assets/termsAndConditions/${serviceName || 'E_LISTA_TERMS_N_CONDITIONS'}.pdf`;

  const onCancel = () => {
    onCloseModal();
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      centered
      footer={null}
      closable={false}
      onCancel={onCancel}
      width="100%"
      bodyStyle={{
        display: 'flex',
        padding: '8px 0px',
        flexDirection: 'column',
        height: '90vh'
      }}
      visible={visible}
      style={{ maxWidth: '600px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%'
        }}>
        <Title
          style={{ fontSize: 20, fontFamily: 'poppins', fontWeight: 600, textAlign: 'center' }}>
          Terms & Conditions
        </Title>
        <CloseCircleOutlined
          style={{ position: 'absolute', right: '5%', fontSize: 24 }}
          onClick={onCloseModal}
        />
        <div style={{ width: '600px', height: '600px' }}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
            <Viewer fileUrl={filePath} defaultScale={1} />
          </Worker>
        </div>
      </div>
    </Modal>
  );
};

TermsAndConditionOverlay.propTypes = {
  onCloseModal: PropTypes.func,
  visible: PropTypes.bool,
  serviceName: PropTypes.string
};

TermsAndConditionOverlay.defaultProps = {
  onCloseModal: () => {},
  visible: false,
  serviceName: ''
};

export default TermsAndConditionOverlay;
