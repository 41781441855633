import { createAPISauceInstance } from 'src/utils/apiSauce';
import apiProxies from 'src/constants/apiProxies';
import endpoints from 'src/constants/endpoints';
import { getLocalStorage } from 'src/utils/localStorage';
import keys from 'src/constants';
import config from 'src/config';
import api from 'src/constants/api';

const TIME_OUT_MESSAGE = 'Payment is processing';

const postPayBill = async ({ formdata, onCompleted = () => {}, onError = () => {}, onTimeout }) => {
  try {
    const accountId = getLocalStorage({ key: keys.ACCOUNT_ID });

    const response = await createAPISauceInstance({
      baseURL: apiProxies.bills_payment,
      headers: {
        'x-account-id': accountId,
        ...config.HTTP_PAY_BILL_HEADER_1,
        ...config.GROWSARI_MARKETPLACE_HTTP_HEADER
      }
    }).post(endpoints?.BILLER__POST_PAY_BILL, formdata);

    const { data: responseData, problem } = response || {};
    const { data, success, message } = responseData || {};

    if (success) {
      onCompleted(data, message);
    } else {
      if (api.TIMEOUT_ERROR === problem) {
        onTimeout(TIME_OUT_MESSAGE);

        return;
      }

      onError(message);
    }
  } catch (e) {
    onError(e);
  }
};

export default postPayBill;
