import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { utc } from 'moment';

import { colors } from 'src/styles';

const styles = {
  timer: {
    fontFamily: 'poppins',
    fontWeight: 700,
    fontSize: 13,
    color: colors.textLowEmphasis,
    width: '100%'
  },
  container: {
    width: '100%',
    marginTop: 24
  }
};

const OTPTimer = ({ seconds = 300, onTimeIsUpCb = () => {} }) => {
  const [counter, setCounter] = useState(seconds);
  const intervalCbRef = useRef(null);

  useEffect(() => {
    if (!counter) {
      clearInterval(intervalCbRef.current);
      onTimeIsUpCb();
    }
  }, [counter, onTimeIsUpCb]);

  useEffect(() => {
    clearInterval(intervalCbRef.current);
    intervalCbRef.current = setInterval(() => {
      setCounter(prev => prev - 1);
    }, 1000);
  }, []);

  const formattedRemainingTime = utc(counter * 1000).format('mm:ss');

  return (
    <div style={styles.container}>
      <span style={styles.timer}>Resend code in {formattedRemainingTime}</span>
    </div>
  );
};

OTPTimer.propTypes = {
  seconds: PropTypes.number,
  onTimeIsUpCb: PropTypes.func
};

OTPTimer.defaultProps = {
  seconds: 300,
  onTimeIsUpCb: () => {}
};

export default OTPTimer;
