import colors from 'src/styles/colors';

const { white, primary } = colors;

const OFF_BOARDING_TEXTS = {
  OFF_BOARDING_TITLE:
    'Starting July 15, 2023, ELista will only be available on the new Saripay app',
  OFF_BOARDING_CONTENT:
    'I-download na ang bagong Saripay app para tuloy-tuloy lang paggamit ng ELista!',
  OFF_BOARDING_DOWNLOAD_BTN1: {
    label: 'Download Saripay app',
    buttonStyles: {
      marginTop: '20px'
    },
    labelStyles: {
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '22px',
      color: white
    }
  },
  OFF_BOARDING_DOWNLOAD_BTN2: {
    label: 'Download the app',
    buttonStyles: {
      width: '151px',
      height: '32px',
      marginTop: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      gap: '4px'
    },
    labelStyles: {
      fontSize: '11px',
      fontWeight: 600,
      lineHeight: '120%'
    }
  },
  OFF_BOARDING_DOWNLOAD_LATER: {
    label: 'I’ll download later',
    buttonStyles: {
      marginTop: '4px',
      backgroundColor: white,
      boxShadow: 'none'
    },
    labelStyles: {
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '22px',
      color: primary
    }
  }
};

export default OFF_BOARDING_TEXTS;
