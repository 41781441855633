import React from 'react';
import { Input, Typography } from 'antd';
import PropTypes from 'prop-types';

import { colors } from 'src/styles';
import './gs-text-input.css';

const { Text } = Typography;

const GSTextInput = ({
  value,
  placeholder,
  onChange,
  label = '',
  required = false,
  maxLength,
  errorMessage = null,
  inputType = 'default',
  specialText = null,
  specialTextStyles = {}
}) => {
  const handleOnCHange = e => {
    const text = e.target.value;

    if (onChange) {
      onChange(text);
    }
  };

  const status = errorMessage ? 'error' : null;

  return (
    <>
      {!!label && (
        <div style={{ textAlign: 'left' }}>
          <Text className="gs-text-input-label" style={{ color: colors.textHigh }}>
            {label}
          </Text>
        </div>
      )}
      <Input
        className="gs-text-input"
        onChange={handleOnCHange}
        style={{ borderRadius: '0.4em' }}
        size="large"
        placeholder={placeholder}
        allowClear
        value={value}
        required={required}
        maxLength={maxLength}
        status={status}
        type={inputType}
      />
      {errorMessage && (
        <div style={{ textAlign: 'left' }}>
          <Text className="gs-text-input-error">{errorMessage}</Text>
        </div>
      )}
      {!!specialText && (
        <div style={{ textAlign: 'left' }}>
          <Text style={specialTextStyles} className="gs-text-input-special-text">
            {specialText}
          </Text>
        </div>
      )}
    </>
  );
};

GSTextInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.string,
  inputType: PropTypes.string,
  specialText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  specialTextStyles: PropTypes.object
};

GSTextInput.defaultProps = {
  value: null,
  placeholder: null,
  onChange: () => {},
  label: '',
  required: false,
  maxLength: undefined,
  errorMessage: null,
  inputType: 'default',
  specialText: null,
  specialTextStyles: {}
};

export default GSTextInput;
