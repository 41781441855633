import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { colors } from 'src/styles';

const { Title } = Typography;

const styles = {
  title: {
    padding: 0,
    margin: 0,
    fontSize: 17,
    fontFamily: 'poppins',
    fontWeight: 700,
    marginLeft: 30
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 12px'
  },
  backIcon: {
    color: colors.primary,
    fontSize: 18
  }
};

const GSBasicHeader = ({ title, customOnBackClick }) => {
  const navigate = useNavigate();

  const onBackClick = () => {
    if (customOnBackClick) {
      customOnBackClick();

      return;
    }

    navigate(-1);
  };

  return (
    <div style={styles.container}>
      <ArrowLeftOutlined style={styles.backIcon} onClick={onBackClick} />
      <Title style={styles.title}>{title}</Title>
    </div>
  );
};

GSBasicHeader.propTypes = {
  title: PropTypes.string,
  customOnBackClick: PropTypes.func
};

GSBasicHeader.defaultProps = {
  title: '',
  customOnBackClick: null
};

export default GSBasicHeader;
