import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authReducer, myWalletReducer } from './reducers';

const reducer = combineReducers({
  authReducer,
  myWalletReducer
});

const store = configureStore({ reducer });

export default store;
