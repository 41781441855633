const BILLER_GET_DISTRIBUTOR_BILLER = '/biller/get-specific-biller-category';
const GROWCREDIT_GET_CREDIT_DETAIL = '/v1/get-stores-list';
const GROWCREDIT_GET_CREDIT_SERVICE_OPTION_LIST = 'v2/credit-service-option/get-list';
const GROWCREDIT_GET_LIMITS = 'v2/credit/get-limits';
const BILLER__POST_PAY_BILL = '/payment/pay-bill';
const GROWCREDIT_GET_EXPECTED_BILLING_DATE = 'v2/transaction/get-expected-billing-date';

const endpoints = {
  BILLER_GET_DISTRIBUTOR_BILLER,
  GROWCREDIT_GET_CREDIT_DETAIL,
  GROWCREDIT_GET_CREDIT_SERVICE_OPTION_LIST,
  GROWCREDIT_GET_LIMITS,
  BILLER__POST_PAY_BILL,
  GROWCREDIT_GET_EXPECTED_BILLING_DATE
};

export default endpoints;
