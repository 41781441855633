import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isAuthorized: false,
  isCheckingAuthorization: true,
  showUnauthorizedModal: false
};

const authReducer = createSlice({
  name: 'authReducer',
  initialState: INITIAL_STATE,
  reducers: {
    actionUpdateAuthReducer: (state, action) => {
      const { payload = {} } = action;

      return { ...state, ...payload };
    },
    actionResetAuthReducer: () => {
      return INITIAL_STATE;
    }
  }
});

export const { actionResetAuthReducer, actionUpdateAuthReducer } = authReducer.actions;

export default authReducer.reducer;
