import services from 'src/constants/services';
import billers from 'src/constants/billers';

const { ELISTA_INTEREST_TYPES } = services;
const { FORM_FIELD_KEYS } = billers;

const isElistaAllowed = ({ isGrowCreditBlocked, isKycDone }) => {
  return !!isGrowCreditBlocked && !!isKycDone;
};

const getElistaCreditLimitByService = ({ creditLimits = [], serviceGroup, type }) => {
  const filteredServices = creditLimits.find(i => {
    const { type: itemServiceGroup, type_id: itemTypeId, is_enabled: isEnabled } = i;

    return itemServiceGroup === serviceGroup && `${itemTypeId}` === type && !!isEnabled;
  });

  const {
    max_limit: maxCreditLimit = 0,
    available_limit: availableLimit = 0,
    total_balance: totalBalance = 0
  } = filteredServices || {};

  const data = {
    totalBalance,
    maxCreditLimit,
    availableLimit,
    filteredServices
  };

  return data;
};

const getProcessingFee = ({ amount = 0, creditInterest = 0, interestType }) => {
  let creditInterestValue = 0;

  if (interestType === ELISTA_INTEREST_TYPES.PERCENTAGE) {
    creditInterestValue = (amount * creditInterest) / 100;
  } else if (interestType === ELISTA_INTEREST_TYPES.PESO) {
    creditInterestValue = creditInterest;
  }

  return creditInterestValue;
};

const getAmountInFormField = ({ formInputs }) =>
  formInputs.find(field => field.key === FORM_FIELD_KEYS.amount) || {};

const myWallet = {
  isElistaAllowed,
  getElistaCreditLimitByService,
  getProcessingFee,
  getAmountInFormField
};

export default myWallet;
