import services from 'src/constants/services';
import { getFormattedPesos } from 'src/utils/helpers';

const { ELISTA_INTEREST_TYPES } = services;

const generateOrderId = () => {
  const currentTimeInMilliSeconds = String(new Date().getTime());

  return `GS${currentTimeInMilliSeconds}${Math.random().toString(36).substring(2, 7)}`;
};

const getBaseProcessingRate = (baseProcessingRate, type) => {
  if (type === ELISTA_INTEREST_TYPES.PERCENTAGE) {
    return `${baseProcessingRate}%`;
  }
  return getFormattedPesos(baseProcessingRate, 2);
};

export { generateOrderId, getBaseProcessingRate };
