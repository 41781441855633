import React from 'react';
import { Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import { colors } from 'src/styles';

const { Option } = Select;
const { Text } = Typography;

const DynamicSpinner = ({ field, setFormFieldValue }) => {
  const { options = [], default_value: dynamicPlaceholder, key, label, errorMessage } = field;

  const onChange = selectValue => {
    setFormFieldValue(prevValue => {
      const spreadPrevValue = [...prevValue];
      const findIndex = spreadPrevValue.findIndex(i => i.key === key);

      try {
        spreadPrevValue[findIndex].value = selectValue;
      } catch {
        //
      }

      return spreadPrevValue;
    });
  };

  const status = errorMessage ? 'error' : null;

  return (
    <>
      {!!label && (
        <div style={{ textAlign: 'left' }}>
          <Text className="gs-text-input-label" style={{ color: colors.textHigh }}>
            {label}
          </Text>
        </div>
      )}
      <Select
        style={{ width: '100%', textAlign: 'left', height: 40 }}
        showSearch
        placeholder={dynamicPlaceholder}
        optionFilterProp="children"
        onChange={onChange}
        status={status}>
        {options.length &&
          options.map((opt, index) => (
            <Option key={`${opt.value}_${index + 1}`} value={opt?.value}>
              {opt.label}
            </Option>
          ))}
      </Select>
      {errorMessage && (
        <div style={{ textAlign: 'left' }}>
          <Text className="gs-text-input-error">{errorMessage}</Text>
        </div>
      )}
    </>
  );
};

DynamicSpinner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object,
  setFormFieldValue: PropTypes.func
};

DynamicSpinner.defaultProps = {
  field: {},
  setFormFieldValue: () => {}
};

export default DynamicSpinner;
