import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useGenerateOTP } from 'src/hooks/auth';
import { colors } from 'src/styles';

const styles = {
  button: {
    backgroundColor: 'inherit',
    marginTop: 24,
    fontFamily: 'poppins',
    fontWeight: 700,
    fontSize: 13,
    color: colors.primary,
    width: '100%'
  }
};

const ResendOTPButton = ({ onSuccessResendOTP = () => {} }) => {
  const { state = {} } = useLocation();
  const { mobileNumber = null } = state || {};

  const onCompleted = useCallback(() => {
    onSuccessResendOTP();
  }, [onSuccessResendOTP]);

  const { runGenerateOTP, loadingGenerateOTP } = useGenerateOTP({ onCompleted });

  const onPressResend = useCallback(() => {
    runGenerateOTP({ mobileNumber });
  }, [mobileNumber, runGenerateOTP]);

  const label = loadingGenerateOTP ? ' Resending code...' : ' Resend code';

  return (
    <Button
      disabled={loadingGenerateOTP}
      loading={loadingGenerateOTP}
      type="text"
      style={styles.button}
      onClick={onPressResend}>
      {label}
    </Button>
  );
};

ResendOTPButton.propTypes = {
  onSuccessResendOTP: PropTypes.func
};

ResendOTPButton.defaultProps = {
  onSuccessResendOTP: () => {}
};

export default ResendOTPButton;
