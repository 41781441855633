import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import './distributor-supplier-list.css';
import { colors } from 'src/styles';
import { clickMerchant } from 'src/firebaseEvents';

const { Text } = Typography;

const styles = {
  container: {
    borderColor: colors.borderTertiary
  },
  supplierImg: {
    borderColor: colors.borderTertiary
  },
  rightIcon: {
    color: colors.textLowEmphasis
  }
};

const DistributorSupplierItem = ({ distributorItem = {} }) => {
  const navigate = useNavigate();
  const { name, image } = distributorItem;

  const onClickDistributorSupplier = useCallback(() => {
    clickMerchant({
      otherParams: {
        biller_id: distributorItem?.id
      }
    });
    navigate('/pay_distributor_supplier_form', { state: { distributorItem } });
  }, [distributorItem, navigate]);

  return (
    <button
      type="button"
      onClick={onClickDistributorSupplier}
      style={styles.container}
      className="distributor-supplie-item-container">
      <img
        alt="supplier-logo"
        className="distributor-supplie-item-logo"
        src={image}
        style={styles.supplierImg}
      />
      <Text className="distributor-supplie-item-label">{name}</Text>
      <RightOutlined style={styles.rightIcon} />
    </button>
  );
};

DistributorSupplierItem.propTypes = {
  distributorItem: PropTypes.shape({
    form_fields: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    recipient_number: PropTypes.string
  })
};

DistributorSupplierItem.defaultProps = {
  distributorItem: {}
};

export default DistributorSupplierItem;
