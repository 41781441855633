const jsonParseObjectString = (objectString, defualtValue = {}) => {
  try {
    return JSON.parse(objectString);
  } catch {
    return defualtValue;
  }
};

const jsonParseArrayString = (arrayString, defualtValue = []) => {
  try {
    return JSON.parse(arrayString);
  } catch {
    return defualtValue;
  }
};

const getCentavosInValue = value => {
  const defaultVal = '00';

  try {
    return (Number(value) % 1).toFixed(2).split('.')?.[1] || defaultVal;
  } catch {
    return defaultVal;
  }
};

const getFormattedNumber = (value, toFixedNumber = 2, returnInNumber = false) =>
  returnInNumber
    ? Number(Number(value)?.toFixed(toFixedNumber))
    : Number(value)?.toFixed(toFixedNumber);

const getFormattedPesos = (value, toFixedNumber = 2, roundOffValue = true, withPesoSign = true) => {
  try {
    const decimalPoints = value - Math.floor(value);
    const baseValue =
      roundOffValue || decimalPoints?.toString()?.length > toFixedNumber
        ? getFormattedNumber(value, toFixedNumber)
        : value;
    const withComma = baseValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return withPesoSign ? `₱${withComma}` : withComma;
  } catch {
    return value;
  }
};

const getFormattedString = name => {
  const regex = /\(([^)]+)\)/;
  const match = regex.exec(name);

  return match ? match[1] : name?.replace(/[\s_]/g, '');
};

export {
  jsonParseArrayString,
  jsonParseObjectString,
  getCentavosInValue,
  getFormattedNumber,
  getFormattedPesos,
  getFormattedString
};
