import apiProxies from 'src/constants/apiProxies';
import { createAPISauceInstance } from 'src/utils/apiSauce';

import endpoint from 'src/constants/endpoints';
import { unauthorizedModalHandler } from 'src/utils/authorization';

const getStoreCreditServiceList = async ({
  onCompleted = () => {},
  dispatch = null,
  onError = null
}) => {
  const response = await createAPISauceInstance({
    baseURL: apiProxies?.grow_credit,
    params: {
      credit_service_status_v2: `[0,1]`
    }
  }).get(endpoint?.GROWCREDIT_GET_CREDIT_SERVICE_OPTION_LIST);

  const { data: responseData } = response;
  const { data, code, success } = responseData;

  if (success && onCompleted) {
    onCompleted(data);
  } else {
    unauthorizedModalHandler({ dispatch, code, onError, response });
  }
};

export default getStoreCreditServiceList;
