import { logFirebaseEvent } from 'src/utils/firebase';
import { OTP_FAILED } from 'src/constants/eventNames';

const otpFailed = ({ otherParams = {} } = {}) => {
  logFirebaseEvent({
    eventName: OTP_FAILED,
    eventParams: {
      ...otherParams
    }
  });
};

export default otpFailed;
