import moment from 'moment';

import endpoints from 'src/constants/endpoints';
import apiProxies from 'src/constants/apiProxies';
import { createAPISauceInstance } from 'src/utils/apiSauce';
import { getLocalStorage } from 'src/utils/localStorage';
import keys from 'src/constants';
import billers from 'src/constants/billers';

import { unauthorizedModalHandler } from 'src/utils/authorization';

const getCreditBillingDate = async ({
  isOptions = false,
  creditServiceKey,
  onCompleted = () => {},
  onError = () => {},
  dispatch
} = {}) => {
  try {
    const accountId = getLocalStorage({ key: keys.ACCOUNT_ID });
    const path = `${endpoints.GROWCREDIT_GET_EXPECTED_BILLING_DATE}${isOptions ? '-options' : ''}`;
    const params = {
      account_id: accountId,
      expected_confirmation_date: moment(new Date()).format('yyyy-MM-DD'),
      credit_service_key: creditServiceKey || billers.CREDIT_SERVICE_KEY
    };

    const response = await createAPISauceInstance({
      baseURL: apiProxies?.grow_credit,
      params
    }).get(path);

    const { data: responseData } = response;
    const { data = {}, success = false, code } = responseData;

    if (success && onCompleted) {
      onCompleted(data, success);
    } else {
      unauthorizedModalHandler({ dispatch, code });

      return {
        data: null,
        success: false
      };
    }
  } catch {
    onError({}, false);
  }

  return null;
};

export default getCreditBillingDate;
