import { LoginScreen, VerifyOTPScreen } from 'src/components/pages';

const publicRouter = [
  {
    path: '/login',
    element: <LoginScreen />,
    caseSensitive: true
  },
  {
    path: '/verify-otp',
    element: <VerifyOTPScreen />,
    caseSensitive: true
  }
];

export default publicRouter;
